import { UserProfile } from '@auth0/nextjs-auth0/client';
import {
  ApplicationInsights,
  SeverityLevel
} from '@microsoft/applicationinsights-web';
import { CtaTrackingProperties } from '../models/app-insight-tracking';

export enum LogLevel {
  DEBUG = 0,
  INFO = 1,
  WARN = 2,
  ERROR = 3
}

let clientAI: ApplicationInsights | undefined;
export function useApplicationInsights() {
  clientAI = new ApplicationInsights({
    config: {
      connectionString: process.env.APPINSIGHTS_CONNECTIONSTRING,
      disableCorrelationHeaders: true,
      isCookieUseDisabled: true,
      isStorageUseDisabled: true
    }
  });
  clientAI.loadAppInsights();
}

export const trackPageView = () => {
  clientAI?.trackPageView();
};

export interface IUser extends UserProfile {
  nzte_roles?: string[];
}

export type LoggerContext = { [key: string]: any };

export const AIEvent = (
  name: string,
  properties: { [key: string]: any }
): void => {
  clientAI?.trackEvent({ name }, properties);
};

export const trackLiveDealsCtaClick = (properties: CtaTrackingProperties) => {
  AIEvent('cta-click', properties);
};

/** Map events to a given user */
export const setUserContext = (user?: IUser): void => {
  if (clientAI == null) {
    return;
  }

  if (user == null) {
    clientAI.clearAuthenticatedUserContext();
    return;
  }

  // user_AuthenticatedId can not contain ,;=|
  const userId = user.sub.includes('waad')
    ? user.sub.split('waad|')[1]
    : user.sub.includes('auth0|')
    ? user.sub.split('auth0|')[1]
    : '';
  const userType = user.sub.includes('waad')
    ? 'nztestaff'
    : user.nzte_roles
    ? user.nzte_roles.includes('advisor')
      ? 'advisor'
      : 'investor'
    : 'investor';
  clientAI.setAuthenticatedUserContext(userId, userType, true);
};

/** Log a warning. A warning is when something goes wrong, but is recoverable or has minimal user impact. */
export const logWarn = (message: string, meta?: LoggerContext): void => {
  console.warn(message, meta); // eslint-disable-line no-console

  clientAI?.trackTrace({ message, severityLevel: SeverityLevel.Warning }, meta);
};

/** Log an error. An error is when something unexpected goes wrong which impacts the user. */
export const logError = (
  message: string | Error,
  meta?: LoggerContext
): void => {
  console.error(message, meta); // eslint-disable-line no-console

  if (typeof message === 'string') {
    clientAI?.trackTrace({ message, severityLevel: SeverityLevel.Error }, meta);
    return;
  }

  clientAI?.trackException({
    error: message,
    properties: meta
  });
};
