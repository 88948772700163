// this will be used to story the feature flags context so that we don't need to pass the flag into child components
import React, { useContext, useState } from 'react';
import {
  GTMVirtualPageViewPageType,
  PageTrackingVariables
} from '../../helpers/useGaTracking';
export interface PageTrackingProviderProps {
  children: React.ReactNode;
}

export interface PageTrackingContextData {
  page: PageTrackingVariables;
  updatePageContext: (data: PageTrackingVariables) => void;
}

const defaultValues: PageTrackingContextData = {
  page: {
    pageType: GTMVirtualPageViewPageType.Other
  },
  updatePageContext: () => {}
};

export const PageTrackingContext = React.createContext(defaultValues);

export const usePageTrackingContext = () => useContext(PageTrackingContext);

export const PageTrackingContextProvider = (
  props: PageTrackingProviderProps
) => {
  const { children } = props;
  const [pageState, setPageState] = useState<PageTrackingVariables>(null);

  const updatePageContext = (data: PageTrackingVariables) => {
    setPageState(data);
  };

  return (
    <PageTrackingContext.Provider
      value={{
        page: pageState,
        updatePageContext
      }}
    >
      {children}
    </PageTrackingContext.Provider>
  );
};
