// Import all the fonts using npm packages - https://chakra-ui.com/docs/styled-system/recipes/using-fonts
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/400-italic.css';
import '@fontsource/montserrat/700-italic.css';
import '@fontsource/antonio/400.css';
import '@fontsource/antonio/500.css';
import '@fontsource/antonio/600.css';
import '@fontsource/antonio/700.css';
