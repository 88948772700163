import { jsonParse } from '../utils/jsonParse';

export enum LocalStorageKey {
  User = 'user'
}

export const getLocalStorage = (key: LocalStorageKey) => {
  if (typeof window !== 'undefined') {
    const item = window.localStorage.getItem(key);
    // Parse stored json or if none return initialValue
    return jsonParse(item) ?? null;
  }
  return null;
};
export const setLocalStorage = (key: LocalStorageKey, value: any) => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
};
