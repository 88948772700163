import { theme as chakraTheme } from '@chakra-ui/react';
import { getAdditionalSizes } from './theme-utils';
import { extendDesignSystemBaseTheme } from '@nzte/pallet-ui';

const theme = extendDesignSystemBaseTheme({
  colors: {
    ...chakraTheme.colors,
    black: '#000000',
    blacks: {
      90: 'rgba(0,0,0,0.90)',
      75: 'rgba(0,0,0,0.75)',
      65: 'rgba(0,0,0,0.65)',
      50: 'rgba(0,0,0,0.5)',
      35: 'rgba(0,0,0,0.35)',
      25: 'rgba(0,0,0,0.25)'
    },
    dark: '#242429',
    base: '#252525',
    white: '#ffffff',
    whites: {
      65: 'rgba(255,255,255,0.65)',
      50: 'rgba(255,255,255,0.5)',
      35: 'rgba(255,255,255,0.35)',
      25: 'rgba(255,255,255,0.25)'
    },
    spider: {
      line: '#58C2B4',
      bg: 'rgba(88, 194, 180, 0.15)'
    },
    grey: {
      '01': '#797979',
      '02': '#979797',
      '03': '#cbcaca',
      '04': '#f3f3f3',
      '05': '#f9f9f9',
      '06': '#f4fbfa',
      '07': '#d6d6d6',
      '08': '#ececec',
      '09': '#757575',
      '10': '#EDECE0',
      '11': '#E4E2D1',
      '12': '#D2CFB2',
      '13': '#4D4D4D',
      '14': '#343A40',
      '15': '#737373',
      '16': '#2E2E2E',
      '17': '#818181'
    },
    success: '#23a67a',
    info: '#027bc3',
    warning: '#f1a92e',
    warnings: {
      light: 'rgba(254, 207, 51, 0.03)'
    },
    alert: '#c81731',
    alerts: {
      light: '#fde6ea',
      'light-2': '#ffeff2',
      medium: 'rgba(236, 26, 57, 0.5)'
    },
    primary: {
      '01': '#58c7dd' // cyan
    },
    navigationSky: {
      100: '#2eb9d4',
      200: '#a6eaf7',
      500: '#58C7DD'
    },
    orange: {
      50: '#FEF7EB',
      60: '#FF8E00',
      70: '#FC6400',
      80: '#FF2C00',
      100: '#F1BE34',
      500: '#F1A92E',
      600: '#E4E2D1',
      700: '#ED6C58',
      800: '#E8432A',
      900: '#FC6400'
    },
    neutral: {
      light: '#EDECE0',
      dark: '#D2CFB2'
    },
    text: {
      secondary: '#757575'
    }
  },
  fonts: {
    ...chakraTheme.fonts,
    black: `'Montserrat', sans-serif`,
    body: `'Montserrat', sans-serif`,
    heading: `'Montserrat', sans-serif`
  },
  fontSizes: {
    ...chakraTheme.fontSizes
  },
  lineHeights: {
    ...chakraTheme.lineHeights
  },
  fontWeights: {
    ...chakraTheme.fontWeights
  },
  zIndices: {
    ...chakraTheme.zIndices,
    header: 150,
    mobileHeader: 999,
    profileNavMobile: 150,
    modalOverlay: 1000,
    modalContent: 2000,
    avatarUploadModal: 150,
    overlay: 130
  },
  space: {
    ...chakraTheme.space,
    ...getAdditionalSizes()
  },
  sizes: {
    ...chakraTheme.sizes,
    container: {
      ...chakraTheme.sizes.container,
      xl: '1168px'
    },
    ...getAdditionalSizes()
  },
  borders: {
    ...chakraTheme.borders,
    '3px': '3px solid'
  },
  styles: {
    global: {
      ul: {
        listStyleType: 'none',
        paddingLeft: 6,
        marginLeft: 0,
        li: {
          position: 'relative',
          _before: {
            content: "''",
            width: '3px',
            height: '3px',
            background: 'black',
            position: 'absolute',
            left: '-12px',
            top: '9px',
            borderRadius: '50%'
          },
          p: {
            marginBottom: '0 !important'
          }
        }
      },
      ol: {
        listStyleType: 'none',
        paddingLeft: 6,
        marginLeft: 0,
        li: {
          position: 'relative',
          counterIncrement: 'li',
          _before: {
            content: 'counter(li) "."',
            position: 'absolute',
            right: '100%',
            top: 0,
            fontWeight: 'bold',
            marginRight: '6.4px'
          },
          p: {
            marginBottom: '0 !important'
          }
        }
      }
    }
  }
});

/**
 * Deep Loop into defaultTheme Object and find the child array for value
 *
 * @param brandColor object varible to find the color code
 *
 * @returns Hex Color Code
 */
export const generateColorCode = (
  brandColor: string = 'primary.01'
): string => {
  if (brandColor === null || brandColor === undefined) {
    brandColor = 'primary.01';
  }

  let values = theme['colors'];
  const brandColorArray = brandColor.split('.');

  for (let i = 0; i < brandColorArray.length; i += 1) {
    values = values[brandColorArray[i]];
  }

  return values;
};

export type Theme = typeof theme;

export default theme;
