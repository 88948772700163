import TagManager from 'react-gtm-module';
import Cookies from 'js-cookie';
import {
  PageTrackingVariables,
  useVirtualPageViewTracking
} from '../../helpers/useGaTracking';

export class GaUser {
  id?: string = null;
  crmId?: string = null;
  auth0ID?: string = null;
  type?: string = null;
  userRole?: string = null;
  companyCrmId?: string = null;
  companyState?: string = null;
  companyCrmRole?: string = null;
  companyRole?: string = null;
  companySegment?: string = null;
  isMaoriCompany: boolean;
  loggedInStatus: boolean;
  userInitiated: boolean;
  constructor(user: any, justLoggedIn: boolean = false) {
    this.loggedInStatus = false;
    this.isMaoriCompany = false;

    if (user) {
      this.id = user['http://nzte.govt.nz/selected_user_metadata']?.crm_id;
      this.crmId = user['http://nzte.govt.nz/selected_user_metadata']?.crm_id;
      this.auth0ID = user.sub;
      this.type = user.sub.includes('auth0|') ? 'non-staff' : 'staff';
      this.userRole = user.sub.includes('auth0|') ? 'Contact' : 'Staff';
      this.loggedInStatus = true;
      this.userInitiated = justLoggedIn;
    }
  }
}

export function useGoogleAnalytics(): void {
  const gtmId = process.env.GOOGLE_TAG_MANAGER_ID_GA4_E2E;
  // @ts-ignore
  const dataLayer = window.dataLayer || [];
  // Push default consent behavior
  dataLayer.push('consent', 'default', {
    ad_storage: 'granted',
    analytics_storage: 'granted',
    functionality_storage: 'granted',
    personalization_storage: 'granted',
    security_storage: 'granted'
  });
  TagManager.initialize({
    gtmId
  });
}

export function useGoogleAnalyticsUserEvent(
  user: any,
  isLoading: boolean,
  page: PageTrackingVariables
): void {
  if (!isLoading && page) {
    // get just user just logged in status from cookie
    let cookie = Cookies.get(`user-just-logged-in`);
    const gaUser = new GaUser(user, cookie === 'true');

    // set the user just logged in status to false once it has been used
    if (cookie) {
      Cookies.remove('user-just-logged-in');
    }

    // todo: push those parameters to page_view directly using user event
    TagManager.dataLayer({
      dataLayer: {
        event: 'user',
        ...gaUser
      }
    });

    // push virtual page view
    useVirtualPageViewTracking({
      event: 'virtual-page-view',
      version: 1,
      ...page
    });
  }
}
