import { useUser } from '@auth0/nextjs-auth0/client';
import React, { useContext, useEffect, useState } from 'react';
import {
  getLocalStorage,
  LocalStorageKey,
  setLocalStorage
} from '../hooks/useLocalStorage';
import {
  useGoogleAnalytics,
  useGoogleAnalyticsUserEvent
} from '../hooks/useGoogleAnalytics';
import {
  setUserContext,
  trackPageView,
  useApplicationInsights
} from '../appInsightsClient';
import { usePageTrackingContext } from './PageTrackingContext';
import { useRouter } from 'next/router';
import {
  ContentPagePaths,
  GTMVirtualPageViewPageType
} from '../../helpers/useGaTracking';

export interface UserData {
  firstName?: string;
  lastName?: string;
  company?: boolean;
}

export interface UserContextProviderProps {
  children: React.ReactNode;
}

export interface UserContextData {
  user: UserData | null;
  updateUserContext: (data: UserData) => void;
}

const defaultValues: UserContextData = {
  user: null,
  updateUserContext: () => {}
};

export const UserContext = React.createContext(defaultValues);

export const useUserContext = () => useContext(UserContext);

export const UserContextProvider = (props: UserContextProviderProps) => {
  const { children } = props;
  const { user, isLoading } = useUser();
  const router = useRouter();
  if (!user && !isLoading) {
    setLocalStorage(LocalStorageKey.User, null);
  }
  const userLocal = getLocalStorage(LocalStorageKey.User);
  const [userState, setUserState] = useState(userLocal);
  const { page } = usePageTrackingContext();
  const updateUserContext = (data: UserData) => {
    setUserState({
      ...userState,
      ...data
    });
    setLocalStorage(LocalStorageKey.User, {
      ...userState,
      ...data
    });
  };

  useEffect(() => {
    useApplicationInsights();
    useGoogleAnalytics();
  }, []);

  // do not remove - this will affect foundamental of tracking in app insight
  useEffect(() => {
    if (!isLoading) {
      setUserContext(user);
      trackPageView();
    }
  }, [user, isLoading]);

  // do not remove - this will affect foundamental of tracking in GA4
  useEffect(() => {
    let pageData = page;
    if (!ContentPagePaths.includes(router.pathname)) {
      pageData = {
        pageType: GTMVirtualPageViewPageType.Other
      };
    }
    useGoogleAnalyticsUserEvent(user, isLoading, pageData);
  }, [user, isLoading, page]);

  return (
    <UserContext.Provider
      value={{
        user: userState,
        updateUserContext
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
