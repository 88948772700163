/* eslint-disable no-unused-vars,  no-undef */
import React from 'react';
import App from 'next/app';
import Head from 'next/head';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import '../styles/index.scss';
import theme from '../styles/chakra/theme';
import '../styles/fonts';
import { UserContextProvider } from '../lib/context/UserContext';
import { PageTrackingContextProvider } from '../lib/context/PageTrackingContext';
import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react';
import { commonVariables } from '../server/ssrQueries/helpers';
import { DesignSystemProvider } from '@nzte/pallet-ui';

class MyApp extends App {
  render() {
    const { Component, pageProps } = this.props;
    const isPreview = commonVariables().preview;
    return (
      <>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
        </Head>
        <ContentfulLivePreviewProvider
          locale="en-US"
          enableInspectorMode={false}
          enableLiveUpdates={isPreview}
        >
          <PageTrackingContextProvider>
            <UserProvider>
              <UserContextProvider>
                <DesignSystemProvider baseTheme={theme}>
                  <Component {...pageProps} />
                </DesignSystemProvider>
              </UserContextProvider>
            </UserProvider>
          </PageTrackingContextProvider>
        </ContentfulLivePreviewProvider>
      </>
    );
  }
}

export default MyApp;
