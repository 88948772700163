import TagManager from 'react-gtm-module';
import { logError } from '../lib/appInsightsClient';

export type GTMVariables =
  | GTMLiveDealsDLVariables
  | GTMLiveDealsAppLoadDLVariables;

export interface PageTrackingVariables {
  /* Page type - e.g. direct-investment or managed-funds for live deals pages */
  pageType: GTMVirtualPageViewPageType;
  /* Page title - e.g. deal title for deal detial page or 'Live Deals' for listing page */
  title?: string;
  /* Page sub type 1 - e.g. live deals subtype 1 could have detail, listing, share, preview page type  */
  subType1?: GTMVirtualPageViewSubType1;
  /* Page sub type 2 - e.g. live deals subtype 2 could be deal types (nzte-prepared or aip only) */
  subType2?: string;
  /* CRM id or Contentful id, depending on "contentSourceSystemId". */
  contentId?: string;
  /* crm or contentful */
  contentSourceSystemId?: GTMVirtualPageViewContentSource;
}

// the rest of paths will be tracked as 'other' page type
export const ContentPagePaths = [
  '/blog',
  '/blog/[pid]',
  '/page/[pid]',
  '/livedeals',
  '/livedeals/[pid]',
  '/livedeals/sharelink/[pid]',
  '/livedeals/preview/[pid]',
  '/livedeals/managed-funds/[pid]',
  '/livedeals/about',
  '/form/[pid]',
  '/jobsatNZTE',
  '/jobsatNZTE/[pid]',
  '/',
  '/home'
];

export interface GTMVirtualPageViewVariables extends PageTrackingVariables {
  event: string;
  version?: number;
}

export enum GTMVirtualPageViewContentSource {
  Crm = 'crm',
  Contentful = 'contentful'
}

export enum GTMVirtualPageViewSubType1 {
  Listing = 'listing',
  Detail = 'detail',
  Preview = 'preview',
  Share = 'share'
}

export enum GTMVirtualPageViewPageType {
  // live deals pages
  DirectInvestment = 'direct-investment',
  ManagedFunds = 'managed-funds',

  // general nzte pages
  Page = 'page',
  Blog = 'blog',
  Form = 'form',
  Job = 'job', // not sure if this kind of page is used or not
  Other = 'other' // this is including all other pages which are not content pages, e.g. my account, sitemap, search, terms, unsubscribe, aip calculator
}

export type GTMLiveDealsAppLoadDLVariables = {
  displayMode?: string;
  userAgent?: string;
  platform?: string;
  uuid?: string; // auth0 id
};

export type GTMLiveDealsDLVariables = {
  event?: string;
  isPreview?: boolean;
  isAipDeal?: boolean;
  dealName?: string;
  dealCrmId?: string;
  dealContentfulId?: string;
  dealType?: string;
};

type LiveDealsDataLayerArgs = {
  dataLayer?: GTMVariables;
  dataLayerName?: string | undefined;
};

export const usePageGaTacking = (tagMangerArgs: LiveDealsDataLayerArgs) => {
  TagManager.dataLayer(tagMangerArgs);
};

export const useVirtualPageViewTracking = (
  virtualPageViewArgs: GTMVirtualPageViewVariables
) => {
  TagManager.dataLayer({
    dataLayer: virtualPageViewArgs
  });
};

export const useAipCalculatorTracking = (tagMangerArgs) => {
  try {
    // push events to normal window data layer if it is embedded in another page
    if (window !== window.parent) {
      // @ts-ignore
      const dataLayer = window.parent.dataLayer || [];
      // @ts-ignore
      dataLayer.push(tagMangerArgs);
    } else {
      // @ts-ignore
      const dataLayer = window.dataLayer || [];
      // @ts-ignore
      dataLayer.push(tagMangerArgs);
    }
  } catch (error) {
    logError('Failed to push datalayer for AIP Calculator', error);
  }
};

export enum MegaNavEvent {
  OpenMenu = 'meganav-open-menu',
  CloseMenu = 'meganav-close-menu',
  LinkClick = 'meganav-link-click'
}

export type GTMNavDLVariables = {
  event: MegaNavEvent;
  navMenuIndex: number;
  navMenuName: string;
  navMenuLinkText?: string;
};

export const useNavTracking = (tagMangerArgs: GTMNavDLVariables) => {
  TagManager.dataLayer({
    dataLayer: {
      event: tagMangerArgs.event,
      tabName: tagMangerArgs.navMenuName,
      tabIndex: tagMangerArgs.navMenuIndex,
      megaNavLinkText: tagMangerArgs.navMenuLinkText
    }
  });
};
