export const pxToRem = (px: number, baseFontSize = 16): string => {
  // rem unit will be related to this value
  const rem = px / baseFontSize;
  return `${rem}rem`;
};

export const getAdditionalSizes = () => {
  const start = -6;
  const end = 100;
  const unit = 0.25;

  const sizes: Record<number, string> = {};
  for (let i = start; i < end; i += 1) {
    sizes[i] = `${unit * i}rem`;
  }
  return sizes;
};
